import React, { useState } from 'react';
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBCollapse,
  MDBDropdownToggle,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownLink
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

function Header() {
    const [showNavColorSecond, setShowNavColorSecond] = useState(false);
    const doWork = () =>{
        if(window.location.pathname.indexOf('/about') !==-1) {
                setShowNavColorSecond(!showNavColorSecond)
            if(!showNavColorSecond )
            document.getElementById('overlay-div').style.display = "none"
            else{
                setTimeout(() => {
                    document.getElementById('overlay-div').style.display = "block"
                }, 100);
            }
        }
        else if(window.location.pathname.indexOf('/product') !==-1) {
                setShowNavColorSecond(!showNavColorSecond)
            if(!showNavColorSecond )
            document.getElementById('overlay-divpro').style.display = "none"
            else{
                setTimeout(() => {
                    document.getElementById('overlay-divpro').style.display = "block"
                }, 100);
            }
        }
        else
        setShowNavColorSecond(!showNavColorSecond)
        
        
    }
  
    return (
        <div>
             <MDBNavbar expand='lg' dark bgColor='dark' style={{minHeight:"70px"}}>
                <MDBContainer fluid style={{display:"flex"}}>
                    <Link to ='/'><MDBNavbarBrand className='logoname'>
                        {/* <MDBIcon icon='home' size='xs' /> */}
                        Northern India Leather Cloth Mfg. Co. (P)Ltd.</MDBNavbarBrand> </Link>
                    <MDBNavbarToggler
                    type='button'
                    data-target='#navbarColor02'
                    aria-controls='navbarColor02'
                    aria-expanded='true'
                    aria-label='Toggle navigation'
                    onClick={doWork}
                    >
                    <MDBIcon icon='bars' fas />
                    </MDBNavbarToggler>
                    <MDBCollapse show={showNavColorSecond} navbar id='navbarColor02'>
                    <div style={{flexGrow:"1"}}></div>
                    <MDBNavbarNav className='me-auto mb-2 mb-lg-0' fullWidth={false}>

                    <MDBNavbarItem><MDBNavbarLink href ='/'>Home</MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                        <MDBDropdown>
                            <MDBDropdownToggle tag='a' className='nav-link' style={{cursor:"pointer"}}>
                            About
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                            <MDBDropdownItem>
                                <MDBDropdownLink href='/about'>Overview</MDBDropdownLink>
                            </MDBDropdownItem>
                            <MDBDropdownItem>
                                <MDBDropdownLink href='/about/boardofdirectors'>Board of Directors</MDBDropdownLink>
                            </MDBDropdownItem>
                            <MDBDropdownItem>
                                <MDBDropdownLink href='/about/vision&values'>Vision and values</MDBDropdownLink>
                            </MDBDropdownItem>
                            <MDBDropdownItem>
                            <MDBDropdownLink href='/about/industrialbackground'>Industrial Background</MDBDropdownLink>
                            </MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                        </MDBNavbarItem>

                    <MDBNavbarItem>
                        <MDBDropdown>
                            <MDBDropdownToggle tag='a' className='nav-link' style={{cursor:"pointer"}}>
                            Products
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                            <MDBDropdownItem>
                            <MDBDropdownLink href='/products'>Automotive</MDBDropdownLink>
                            </MDBDropdownItem>
                            <MDBDropdownItem>
                                <MDBDropdownLink href='/products/footwear' >Footwear</MDBDropdownLink>
                            </MDBDropdownItem>
                            <MDBDropdownItem>
                                <MDBDropdownLink href='/products/furnishing'>Furnishing</MDBDropdownLink>
                            </MDBDropdownItem>
                            <MDBDropdownItem>
                                <MDBDropdownLink href='/products/sportinggoods'>Sporting Goods</MDBDropdownLink>
                            </MDBDropdownItem>
                            <MDBDropdownItem>
                                <MDBDropdownLink href='/products/leathergoods'>Leather Goods</MDBDropdownLink>
                            </MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                        </MDBNavbarItem>

                        {/* <MDBNavbarItem>
                        <Link to='/quality'><MDBNavbarLink >Quality Assurance</MDBNavbarLink></Link>
                        </MDBNavbarItem> */}

                        {/* <MDBNavbarItem>
                        <Link to ='/clients'><MDBNavbarLink>Clients</MDBNavbarLink></Link>
                        </MDBNavbarItem> */}

                        <MDBNavbarItem><MDBNavbarLink href='/careers'>Careers</MDBNavbarLink>
                        </MDBNavbarItem>

                        <MDBNavbarItem><MDBNavbarLink href='/contact'>Contact Us</MDBNavbarLink>
                        </MDBNavbarItem>

                    </MDBNavbarNav>
                    </MDBCollapse>
                </MDBContainer>
                </MDBNavbar>
        </div>
    )
}

export default Header
