import './App.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from './Components/Header';
import Contactus from './Components/Contactus';
import Footer from './Components/Footer';

import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import Careers from './Components/Careers';
import About from './Components/About';
import Products from './Components/Products';
import Home from './Components/Home';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        
        <Header />

          <Switch>

          <Route exact path='/'>
            <Home />
          </Route>

          <Route path='/contact'>
            <Contactus />
          </Route>

          <Route path='/careers'>
            <Careers />
          </Route>

          <Route path='/about'>
            <About />
          </Route>

          <Route path='/products'>
            <Products />
          </Route>

        </Switch>

        <Footer />

      </BrowserRouter>
    </div>
  );
}

export default App;
