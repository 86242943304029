import React, { useEffect, useState } from 'react'
import ContentBox from './ContentBox'
import './products.css'

import auto from '../Assets/automotive.jpg'
import foot from '../Assets/footware.jpg'
import furnish from '../Assets/furnishing.jpg'
import sport from '../Assets/sport.jpg'
import leather from '../Assets/leathergoods.jpg'

import glampse from "../Assets/autoglampse.jpg"
import skid from "../Assets/skidsafefloor.jpg"
import breath from "../Assets/breathable.jpg"
import floor from "../Assets/floormats.jpg"
import access from "../Assets/access.jpg"
import doubleside from "../Assets/doubleside.jpg"
import equipments from "../Assets/equipments.jpg"
import exclusive from "../Assets/exclusive.jpg"
import faux from "../Assets/faux.jpg"
import footind from "../Assets/footind.jpg"
import ground from "../Assets/ground.jpg"
import vogue from "../Assets/vogue.jpg"
import loadv from "../Assets/load.mp4"
import chappal from "../Assets/chappal.jpg"
import loadm from "../Assets/loadphone.mp4"

import unsupport from "../Assets/unsupport.jpg"
import CommonCarousel from './CommonCarousel'

function Products() {
    const [cls, setCls] = useState("auto")
    const [load, setLoad] = useState(true)
    useEffect(() => {
        setLoad(true)
        setTimeout(() => {
            setLoad(false)
        }, 5000);
    },[cls])
    useEffect(() => {
        if(window.location.pathname === '/products') {
            setCls("auto")
        }
        else if(window.location.pathname === '/products/footwear') {
            setCls("foot")
        }
        else if(window.location.pathname === '/products/furnishing') {
            setCls("furnish")
        }
        else if(window.location.pathname === '/products/sportinggoods') {
            setCls("sport")
        }
        else if(window.location.pathname === '/products/leathergoods') {
            setCls("leather")
        }
    }, [])
    return (
        <div>
            {load && <div className='loading' style={{zIndex:"9",width:"100%", backgroundColor:"#f1f1f1", position:"absolute", height:"90%",top:"70px"}}>
                <center>
                    <video className='loader' width="750" height="500" autoPlay >
                    <source src={window.innerWidth > 1200? loadv :loadm} type="video/mp4"/>
                    </video>
                </center>
            </div>}
            <div hidden={load}>
                <div className="imagedivpro"style={{overflow:"hidden"}} >
                    {cls == 'auto' && <img src={auto} style={{ width:"100%"}} />}
                    {cls == 'foot' && <img src={foot} style={{ width:"100%"}} />}
                    {cls == 'furnish' && <img src={furnish} style={{ width:"100%"}} />}
                    {cls == 'sport' && <img src={sport} style={{ width:"100%"}} />}
                    {cls == 'leather' && <img src={leather} style={{ width:"100%"}} />}
                    
                    <div id="overlay-divpro">
                    {cls == 'auto' && <span id="textpro">
                        Automotive 
                        <hr/>
                    </span>}
                    {cls == 'foot' && <span id="textpro">
                        Footwear
                        <hr/>
                    </span>}
                    {cls == 'furnish' && <span id="textpro">
                        Furnishing
                        <hr/>
                    </span>}
                    {cls == 'sport' && <span id="textpro">
                        Sporting Goods
                        <hr/>
                    </span>}
                    {cls == 'leather' && <span id="textpro">
                        Leather Goods
                        <hr/>
                    </span>}
                    </div></div>
                {/* <div style={{height:"600px", width:"10vw", display:"flex", flexDirection:"column", zIndex:"1", backgroundColor:"white"}}></div> */}
                <div style= {{display:"flex"}}>
                    <div style={{display:"flex", flexDirection:"column"}} id="mySidenav" className="sidenav">
                        
                            <a href="#d" id="auto"  className={cls == 'auto'?"activenav": ""} onClick={()=>setCls('auto')}>Automotive</a>
                            <a href="#v" id="foot" className={cls == 'foot'?"activenav": ""} onClick={()=>setCls('foot')}>Footwear</a>
                            <a href="#rv" id="furnish"  className={cls == 'furnish'?"activenav": ""} onClick={()=>setCls('furnish')}>Furnishing</a>
                            <a href="#drt" id="sport"  className={cls == 'sport'?"activenav": ""} onClick={()=>setCls('sport')}>Sporting Goods</a>
                            <a href="#drt" id="leather"  className={cls == 'leather'?"activenav": ""} onClick={()=>setCls('leather')}>Leather Goods</a>
                            <div className="features" style={{margin:"150px 0 0 0px", backgroundColor:"rgb(255, 246, 227)", padding:"20px"}}>
                                <h1 style={{marginLeft:"15px", color:"#6d4c41", borderBottom:"2px solid #6d4c41"}}>Features</h1>
                                <ul>
                                    <li>Abrasion Resistant</li>
                                    <li>Denim Resistance Finishes</li>
                                    <li>Heavy Metals Free</li>
                                    <li>Anti Microbial Products</li>
                                    <li>Products with Lemon Fragrances</li>
                                    <li>Cool Black</li>
                                    <li>UV Resistant</li>
                                    <li>Weather Resistant</li>
                                    <li>Alcohol Resistant</li>
                                    <li>Colour Fastness</li>
                                    <li>Stain Resistant</li>
                                    <li>High Flexing</li>
                                    <li>Salt Water Resistant</li>
                                    <li>Cleaning Ability</li>
                                    <li>Flammability</li>
                                    <li>Anti-Fogging</li>
                                    <li>Cold Resistance ( Cold Crack)</li>
                                    <li>Mildew Resistant</li>
                                </ul>
                            </div>
                        
                    </div>
                    <div style={{display:"flex", flexDirection:"column", width:"80%", marginBottom:"40px"}}>
                    {cls == 'auto' && <>
                    <ContentBox type={"product"} main={"Auto Glampse"} url={glampse} txt={"NILCO offers a diverse selection of PVC Leather Cloth for the automotive industry. To maintain quality controls, we use a variety of textile substrates that are manufactured in-house. We also have an advantage because we can provide both solid and expanded imitation leather. Our REACH-compliant PVC Leather Cloth is water resistant, anti-bacterial, colorfast, and temperature stable.NILCO is one of the manufacturer in India to have a complete perforation line, making us able to supply breathable PVC Leather."} />
                    <ContentBox type={"product"} main={"Skid Safe Floor"} url={skid} txt={"Skid safe floor is widely used in commercial vehicles as it is spill proof and highly durable. Skid Safe PVC Flooring range is fire retardant, VOC Complaint, temperature stabilized and anti-bacterial. These features make the product ideal for use in small and large trucks, taxis, and other passenger transport vehicles. Skid Safe Vinyl flooring is offered in various thicknesses and with different features."} />
                    <ContentBox type={"product"} main={"Breathable Leatherette"} url={breath} txt={"NILCO offers you breathable nontoxic leather like material, sweat free comfort for your car seat covers, furniture upholstery, shoes and clothing. Breathable material doesn’t only let your skin breath in the scorching heat inside the car but also lets you enjoy the soft and suppleness of car seat covers in the coldest of weather. Our breathable faux leather range keeps your surroundings free from volatile chemicals and bad odour even in the harshest of environments."} />
                    </>
                    }
                    {cls == 'foot' && <>
                    <ContentBox type={"product"} main={"PVC Leather for Footwear Industry"} url={footind} txt={"NILCO is a pioneer in developing products for the footwear industry. Specifically for high use applications, we are the company in India that offers life span guarantees for its products. Our Walkwell range is used and appreciated by leading OEM manufacturers in India, and we put our weight behind them for quality guarantees."} />
                    <ContentBox type={"product"} main={"Leather Footwear"} url={chappal} txt={"NILCO is a pioneer in footwear products. We are known for high specification materials for the footwear industry. Be it shoe uppers, lining or insole NILCO produces them all and that too for specialty applications including usage in -20 degree environment. We also run the most comprehensive testing lab capable of testing materials in extreme low temperatures."} />
                    <CommonCarousel type={"foot"} />
                    </>
                    }
                    {cls == 'furnish' && <>
                    <ContentBox type={"product"} main={"Faux Leather for Office Furnishings"} url={faux} txt={"NILCO manufactures decorative PU Leather Cloth and contemporary PVC Leather Cloth for office furniture. We boast the texture selection with 100 + textures and unlimited colors to choose from. You may also customize the print and texture as per your requirements and create a product best suited to the establishment."} />
                    <ContentBox type={"product"} main={"Exclusive Leather"} url={exclusive} txt={"NILCO’s PU and PVC Leather Cloth can be used in hotels and other commercial establishments. Compared to fabric upholstery, PVC is an easier maintenance alternative due to its non-absorbing nature."} />
                    <ContentBox type={"product"} main={"Double Side Coated Textiles"} url={doubleside} txt={"Awnings are an increasingly popular alternative to construction for outdoor use. You may install these awnings at entrances or patios easily without huge construction cost to make the area weather resistant. What’s more is that you can fold the awning away and enjoy the sun whenever you feel like."} />  
                    </>
                    }
                    {cls == 'sport' && <>
                    <ContentBox type={"product"} main={"Sports Accessories"} url={access} txt={"NILCO’s PVC Leather is used in the manufacture of sports equipment. Our synthetic leather for the sports industry has high abrasion resistance and is also lined with acrylic to ensure superior durability."} />
                    <ContentBox type={"product"} main={"Guard Floor"} url={ground} txt={"Non-woven backed floors are used as basketball courts and other exercise areas to ensure impact resistance. Guard floor for this purpose is printed in wooden and other decorated patterns or offered in solid vibrant colors."} />
                    <CommonCarousel type="sport" />
                    </>
                    }
                    {cls == 'leather' && <>
                    <ContentBox type={"product"} main={"Vogue Leather"} url={vogue} txt={"Vouge is offered in leather like grains and also in printed variations to be used in clothing and jackets. NILCO has lamination and printing facility to serve the fashion industry better."} />
                    <ContentBox type={"product"} main={"PVC Unsupported Leather"} url={unsupport} txt={"It is used in the manufacturing of diaries and file folders. While not directly used by schools or educational institutes, Appease finds its way to be widely used in the sector"} />
                    <CommonCarousel type="leather" />
                    </>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Products
