import React, { useEffect, useState } from 'react'
import './about.css'
import ContentBox from './ContentBox'
import ContentBox2 from './ContentBox2';

import over from "../Assets/over.jpg"
import board from "../Assets/board.jpg"
import vision from "../Assets/vision.jpg"
import ib from "../Assets/logos.png"
import loadv from "../Assets/load.mp4"
import loadm from "../Assets/loadphone.mp4"

function About() {
    const [cls, setCls] = useState("over")
    const [load, setLoad] = useState(false)

    useEffect(() => {
        setLoad(true)
        setTimeout(() => {
            setLoad(false)
        }, 2500);
    },[cls])

    useEffect(() => {
        if(window.location.pathname === '/about') {
            setCls("over")
        }
        else if(window.location.pathname === '/about/boardofdirectors') {
            setCls("board")
        }
        else if(window.location.pathname === '/about/vision&values') {
            setCls("vv")
        }
        else if(window.location.pathname === '/about/industrialbackground') {
            setCls("ib")
        }
    }, [])
    return (
        <div>
            {load && <div className='loading' style={{zIndex:"9",width:"100%", backgroundColor:"#f1f1f1", position:"absolute", height:"90%",top:"70px"}}>
                <center>
                    <video className='loader' width="750" height="500" autoPlay >
                    <source src={window.innerWidth > 1200? loadv :loadm} type="video/mp4"/>
                    </video>
                </center>
            </div>}
            <div hidden={load}>
                <div className="imagediv" style={{marginBottom:"40px", overflow:"hidden"}}>

                {cls == 'over' && <img src={over} style={{ width:"100%"}} />}
                {cls == 'board' && <img src={board} style={{ width:"100%"}} />}
                {cls == 'vv' && <img src={vision} style={{ width:"100%"}} />}
                {cls == 'ib' && <img src={ib} style={{ width:"100%"}} />}

                <div id="overlay-div">
                    {cls == 'over' && <span id="text">
                        Overview 
                        <hr/>
                    </span>}
                    {cls == 'board' && <span id="text">
                        Board of Director
                        <hr/>
                    </span>}
                    {cls == 'vv' && <span id="text">
                        Vision and Values
                        <hr/>
                    </span>}
                    {cls == 'ib' && <span id="text">
                        Industrial Background
                        <hr/>
                    </span>}
                    </div></div>
                {/* <div style={{height:"600px", width:"10vw", display:"flex", flexDirection:"column", zIndex:"1", backgroundColor:"white"}}></div> */}
                <div style= {{display:"flex"}}>
                    <div style={{display:"flex", flexDirection:"column"}} id="mySidenav" className="sidenav">
                        
                            <a href="#d" id="overview"  className={cls == 'over'?"activenav": ""} onClick={()=>setCls('over')}>Overview</a>
                            <a href="#v" id="board" className={cls == 'board'?"activenav": ""} onClick={()=>setCls('board')}>Board of Director</a>
                            <a href="#rv" id="vision"  className={cls == 'vv'?"activenav": ""} onClick={()=>setCls('vv')}>Vision and Values</a>
                            <a href="#drt" id="industrial"  className={cls == 'ib'?"activenav": ""} onClick={()=>setCls('ib')}>Industrial Background</a>
                        
                    </div>
                    <div style={{display:"flex", flexDirection:"column", width:"100%",marginBottom:"40px"}}>
                    {cls == 'over' && <>
                    <ContentBox2 type={"over"} txt={"Northern India Leather Cloth Mfg. Co. (P)Ltd.(commonly known as Nilco) offers the perfect balance of performance, service, quality, and reduction in material costs with maximum value."}/>
                    <ContentBox2 type={"over"} txt={"The guiding principle of the organization has always been fulfillment of customer requirement and progressive thinking. This guiding principle is now the culture of the organization and ensures that both internal and external customers are satisfied."}/>
                    <ContentBox2 type={"over"} txt={"Our world-class infrastructure consists of a full range of machinery to fulfill our knitting, processing, heat setting, coating, embossing, printing, lacquering and laminating needs. We value quality and innovation, thus, our Physical, Chemical and Product Development Laboratories are capable of testing predominantly all properties of artificial leather for different segments and applications."}/>
                    <ContentBox2 type={"over"} txt={"We have been certified with ISO 9001:2015 (Quality Management System) which is demonstrative of our commitment towards designing and manufacturing of artificial leather through effective implementation of systems and continual improvement in all our processes.Nilco is committed to developing new products, grains, and colors in conjunction with our customers’ requirements. Our past success and future growth is based on 100% commitment to meet or exceed our customer’s quality requirements."}/>
                    </>
                    }
                    {cls == 'board' && <>
                    <ContentBox type={"bod"} main={"Mr. Pradeep Singhal"} url={null} txt={"Mr. Pradeep Singhal, having a background of engineering with an experience of 35 years of doing a business directs at the overall functionality of the business operations. He clearly defines the vision of the company and works to achieve the goals set."}/>
                    <ContentBox type={"bod"} main={"Mr. Kashish Garg"} url={null} txt={"Mr. Kashish Garg, being optimistic is capable of taking opportunities and risks equally with a goal of prospering the business. He is self-motivated and hardworking with his personal interests in the strategic planning of the business."}/>
                    <ContentBox type={"bod"} main={"Mr. Aditya Singhal"} url={null} txt={"Mr. Aditya Singhal, being a Graduate from University of Bradford seeks to have entrepreneurial skills which are defined by his acts of commitment to work and efficiency. He is confident and persuasive when it comes to the business development aspects of the business."}/>
                    <ContentBox type={"bod"} main={"Mr. Akshay Singhal"} url={null} txt={"Mr. Akshay Singhal, having a background in management studies, he works diligently to achieve the set goals of the business. Having a considerate and kind personality, he makes sure that all the stakeholders are satisfied."}/>
                    <ContentBox type={"bod"} main={"Mr. Sahil Singhal"} url={null} txt={"Mr. Sahil Singhal, being a Post-Graduate from Symbiosis International University, He has a clear sense of direction to make sure that the business objectives are achieved. He is an approachable individual who tends to empathize with his subordinates and customers."}/>
                    </>
                    }
                    {cls == 'vv' && <>
                    <ContentBox type={"vv"} main={"Vision"} web={null} url={null} txt={"To be a value creator for Customers, Suppliers, Employees, Shareholders, Statutory & Regulatory Bodies and Society through continual improvement in all our processes and creating an internal environment in the company for innovation."}/>
                    <ContentBox type={"val"} main={"Values"} web={null} url={null} txt={null}/>
                    
                    </>
                    }
                    {cls == 'ib' && <>
                    <ContentBox type={"ib"} main={"Pearl Metal Cast Pvt Ltd."} web={"pearl"} url={null} txt={"We have established ALUMINIUM ALLOY INGOTS manufacturing unit known as PEARL METAL CAST Plot No. 5, Sector-6, Faridabad. Our product range includes all grades of aluminium alloys and aluminium base master alloys (ADC / LM Series) under the flagship of our parent company Steel Forge Faridabad with the same knowledgeable management."}/>
                    <ContentBox type={"ib"} main={"Steel Forge"} web={"forge"} url={null} txt={"Under the leadership of dynamic and professional entrepreneurs, Steel Forge, a leading player in forging segment, is State-of-The-Art manufacturing facility for forged and machined components out of Carbon, Alloy & Stainless Steel (316L, 304L, 321, F9, F11, F22) for automotive, agri-machinery, earthmovers & heavy engineering OEM's application catering the demand of various complex and intricated forgings of our valued customers across the domestic and global market."}/>
                    <ContentBox type={"ib"} main={"JAV Forgings Pvt. Ltd."} web={null} url={null} txt={"Well known manufacturer of small, medium & heavy steel forgings, J.A.V. Forgings Pvt. Ltd. was established in 1984. Thoroughly familiar with all the international standards, J.A.V. manufactures all types of Flanges, Nozzles Flanges, Tube sheets, self re-enforced Nozzle, Forged Fittings, Crank Shafts, Connecting Rods and other forged components. All these items from half kilogram to three and half tonnes of finish-weight can be supplied in as forged, proof machined & fully machined conditions."}/>
                    <ContentBox type={"ib"} main={"Bright Industries Pvt. Ltd."} web={null} url={null} txt={"Plot. No.161, sector 59, Faridabad"}/>
                    
                    </>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
