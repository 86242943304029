import React, { useEffect, useState } from 'react'
import ClientCarousel from './ClientCarousel'
import MainCarousel from './MainCarousel'
import QualityAssurance from './QualityAssurance'
import over from "../Assets/over.jpg"
import { Link } from 'react-router-dom'
import loadv from "../Assets/load.mp4"
import loadm from "../Assets/loadphone.mp4"

function Home() {
    
    const [load, setLoad] = useState(true)
    useEffect(() => {
        setLoad(true)
        setTimeout(() => {
            setLoad(false)
        }, 4000);
    },[])
    return (
        <div>
            {load && <div className='loading' style={{zIndex:"9",width:"100%", backgroundColor:"#f1f1f1", position:"absolute", height:"90%",top:"70px"}}>
                <center>
                    <video className='loader' width="750" height="500" autoPlay >
                    <source src={window.innerWidth > 1200? loadv :loadm} type="video/mp4"/>
                    </video>
                </center>
            </div>}
            {!load && <div>
            <MainCarousel/>
            <div style={{display:"flex", backgroundColor:"#262626", flexDirection:"column", padding:"30px 10%"}}>
                <h3 style={{fontWeight:"bold", fontFamily:"'Courier New', monospace", color:"#6d4c41", margin:"10px"}}> Northern India Leather Cloth Mfg.Co.(P)Ltd.</h3>
                <h5 style={{fontWeight:"bold", fontFamily:"'Courier New', monospace", color:"#bdbdbd", margin:"10px"}}>SINCE 1978</h5>
                <h6 style={{fontWeight:"bold", fontFamily:"'Courier New', monospace", color:"#a3a3a3", margin:"10px"}}>We serve you with the best quality products made with breakthrough technology and that too at competitive price.</h6>
            </div>
            <QualityAssurance />
            <div className="parallax" style={{padding:"50px 100px"}}>
                <h1 style={{color:"white"}}>Careers</h1>
                <h6 className="paratext" style={{color:"#bdbdbd", width:"30%", marginTop:"30px"}}>Individuality is well preserved with independent working and responsibilities,
                     which further allows for overall development.
                    Witness the Nilco as we celebrate togetherness as a family!</h6>
                    <Link to='/careers'><button style={{backgroundColor:"transparent", color:"lightgray", outline:"none", border:"3px solid #6d4c41", marginTop:"20px"}}>Know More</button></Link>
            </div>
            <ClientCarousel />
            </div>}
        </div>
    )
}

export default Home
