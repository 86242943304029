import React from 'react'
import { MDBFooter, MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import { MDBIcon } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

function Footer() {
    const moveToTop = () => {
        console.log("sdfhgufsdguy")
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };
    return (
        <div>
            <button className='top' onClick={moveToTop}>Go to Top</button>
            <MDBFooter className='text-white text-lg-left' style={{backgroundColor:"#222"}}>
            <MDBContainer className='p-4'>
                <MDBRow>
                <MDBCol lg='1' md='6' className='mb-4 mb-md-0'>
                    </MDBCol>

                <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
                <h5 className='mb-0' style={{color:"whitesmoke"}}>About Us</h5>

                    <ul className='list-unstyled mb-0' style={{marginTop:"10px"}}>
                    <li>
                        <a className='footlinks' href='/about'>
                        Overview
                        </a>
                    </li>
                    <li>
                        <a className='footlinks' href='/about/boardofdirectors'>
                        Board of Directors
                        </a>
                    </li>
                    <li>
                        <a className='footlinks' href='/about/vision&values'>
                        Vision & Values
                        </a>
                    </li>
                    <li>
                        <a className='footlinks' href='/about/industrialbackground'>
                        Industrial Background
                        </a>
                    </li>
                    </ul>
                </MDBCol>

                <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
                    <h5 className='mb-0' style={{color:"whitesmoke"}}>Products</h5>

                    <ul className='list-unstyled mb-0' style={{marginTop:"10px"}}>
                    <li>
                        <a className='footlinks' href='/products'>
                        Automotive
                        </a>
                    </li>
                    <li>
                        <a className='footlinks' href='/products/footwear'>
                        Footwear
                        </a>
                    </li>
                    <li>
                        <a className='footlinks' href='/products/furnishing'>
                        Furnishing Goods
                        </a>
                    </li>
                    <li>
                        <a className='footlinks' href='/products/sportinggoods'>
                        Sporting Goods
                        </a>
                    </li>
                    <li>
                        <a className='footlinks' href='/products/leathergoods'>
                        Leather Goods
                        </a>
                    </li>
                    </ul>
                </MDBCol>

                


                <MDBCol lg='4' md='6' className='mb-4 mb-md-0'>
                    <h5 className='mb-0' style={{color:"whitesmoke"}}>Get in Touch</h5>

                    <ul className='list-unstyled' style={{marginTop:"10px"}}>
                    <li>
                        <Link className='footlinksc' to='#!'>
                        <MDBIcon icon='home' /> &nbsp;Plot No. 16, Sector 6, Faridabad, Haryana, 121006
                        </Link>
                    </li>
                    <li>
                        <Link className='footlinksc' to='#!'>
                        <i className="fas fa-envelope"></i> &nbsp;hrg@nilcoindia.in
                        </Link>
                    </li>
                    <li>
                        <Link className='footlinksc' to='#!'>
                        <i className="fas fa-phone-alt"></i> &nbsp;+91-9873344840, +91-9810698884
                        </Link>
                    </li>
                    <li>
                        <Link className='footlinksc' to='#!'>
                        <i className="fas fa-fax"></i> &nbsp;0129-2241584
                        </Link>
                    </li>
                    <li>
                        <a className='footlinks' target='_blank' href='https://www.google.com/maps/dir//28.3641501,77.3180199/@28.36415,77.31802,17z?hl=en'>
                        <i className="fas fa-location-arrow"></i> &nbsp;View us on map
                        </a>
                    </li>
                    </ul>
                </MDBCol>
                </MDBRow>
            </MDBContainer>

            <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                &copy; {new Date().getFullYear()} Copyright:{' '}
                <Link className='footlinks text-white' to='/'>
                NilcoIndia, All rights reserved.
                </Link>
            </div>
            </MDBFooter>
        </div>
  );
}

export default Footer
