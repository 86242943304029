import React from 'react';
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBCarouselElement,
  MDBCarouselCaption,
} from 'mdb-react-ui-kit';

import over from "../Assets/over.jpg"
import one from "../Assets/1.jpg"
import two from "../Assets/2.jpg"

export default function MainCarousel() {
  return (
            <MDBCarousel showControls showIndicators>
            <MDBCarouselInner >
                <MDBCarouselItem itemId={0}>
                <MDBCarouselElement src={over} alt='...' />
                <MDBCarouselCaption>
                    <h3>NilcoIndia</h3>
                    <h4 style={{color:"white"}}>We have been certified with ISO 9001:2015 which is demonstrative of our commitment towards designing and manufacturing of artificial leather.</h4>
                </MDBCarouselCaption>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={1}>
                <MDBCarouselElement src={one} alt='...' />
                <MDBCarouselCaption>
                    {/* <h5>Second slide label</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                </MDBCarouselCaption>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={2}>
                <MDBCarouselElement src={two} alt='...' />
                <MDBCarouselCaption>
                    {/* <h5>Third slide label</h5>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
                </MDBCarouselCaption>
                </MDBCarouselItem>
            </MDBCarouselInner>
            </MDBCarousel>
  );
}