import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


import escorts from "../Assets/Clients/escorts.png"
import ashokleyland from "../Assets/Clients/ashokleyland.png"
import forcemotors from "../Assets/Clients/forcemotors.jpg"
import honda from "../Assets/Clients/honda.png"
import mahindra from "../Assets/Clients/mahindra.png"
import newholland from "../Assets/Clients/newholland.jpg"
import sonalika from "../Assets/Clients/sonalika.jpg"
import tata from "../Assets/Clients/tata.png"
import vespa from "../Assets/Clients/vespa.jpg"
import action from "../Assets/Clients/action.png"
import apex from "../Assets/Clients/apex.jpg"
import aqualite from "../Assets/Clients/aqualite.png"
import campus from "../Assets/Clients/campus.jpg"
import liberty from "../Assets/Clients/liberty.png"
import paragon from "../Assets/Clients/paragon.jpg"



function ClientCarousel() {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
    return (
        <div>
            <center>
            <h1 style={{fontFamily:"calibri", color:"#474747", borderBottom:"1px solid black",}}>Our Happy Clients</h1>
            <Carousel
            containerClass="carousel-container"
            autoPlaySpeed={2500}
            autoPlay={true}
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            keyBoardControl={true}
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-50-px">
            <div><img src={escorts} alt={"escorts"} height="150px" width="200px" /></div>
            <div><img src={ashokleyland} alt={"ashokleyland"} height="150px" width="200px" /></div>
            <div><img src={forcemotors} alt={"forcemotors"} height="150px" width="200px" /></div>
            <div><img src={honda} alt={"honda"} height="150px" width="200px" /></div>
            <div><img src={mahindra} alt={"mahindra"} height="150px" width="200px" /></div>
            <div><img src={newholland} alt={"newholland"} height="150px" width="200px" /></div>
            <div><img src={sonalika} alt={"sonalika"} height="150px" width="200px" /></div>
            <div><img src={tata} alt={"tata"} height="150px" width="200px" /></div>
            <div><img src={vespa} alt={"vespa"} height="150px" width="200px" /></div>
            <div><img src={action} alt={"action"} height="150px" width="200px" /></div>
            <div><img src={apex} alt={"apex"} height="150px" width="200px" /></div>
            <div><img src={aqualite} alt={"aqualite"} height="150px" width="200px" /></div>
            <div><img src={campus} alt={"campus"} height="150px" width="200px" /></div>
            <div><img src={liberty} alt={"liberty"} height="150px" width="200px" /></div>
            <div><img src={paragon} alt={"paragon"} height="150px" width="200px" /></div>
          </Carousel>
          </center>
        </div>
    )
}

export default ClientCarousel
