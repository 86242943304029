import React, { useEffect, useState } from 'react'
import loadv from "../Assets/load.mp4"
import loadm from "../Assets/loadphone.mp4"

function Careers() {
    const [load, setLoad] = useState(true)
    const [name, setname] = useState("");
    const [message, setmessage] = useState("")
    const [phone, setphone] = useState("")
    const [email, setemail] = useState("")
    const [filename,setFilename] = useState("No File Chosen");
    

    useEffect(() => {
        setLoad(true)
        setTimeout(() => {
            setLoad(false)
        }, 2000);
    },[])

    const handleChange = event => {
        setFilename(event.target.files[0].name)
      };
      const handleClick = (e) => {
        e.preventDefault();
       window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=hrg@nilcoindia.in&su=Applying for job&body=Name: ${name}, Contact: ${phone}, Email: ${email}, Message: ${message}&tf=1`);
     }
    return (
        <div>
            {load && <div className='loading' style={{zIndex:"9",width:"100%", backgroundColor:"#f1f1f1", position:"absolute", height:"90%",top:"70px"}}>
                <center>
                    <video className='loader' width="750" height="500" autoPlay >
                    <source src={window.innerWidth > 1200? loadv :loadm} type="video/mp4"/>
                    </video>
                </center>
            </div>}

            <div className='careers'  hidden={load}>
                <div style={{margin:"0 10vw", marginTop:"5vh"}}>
                    <h1 style={{fontFamily:"calibri",fontWeight:"bold"}}>Apply for the Job</h1>
                    <form style={{width:"250px"}}>
                        <input className = 'inputs' type = "text" placeholder="Name" onChange={e => setname(e.target.value)} />
                        <input className = 'inputs' type = "email" placeholder="Email" onChange={e => setemail(e.target.value)} />
                        <input className = 'inputs' type = "tel" placeholder="Phone number" onChange={e => setphone(e.target.value)} />
                        <textarea placeholder="Message" className='inputarea' rows={4}  onChange={e => setmessage(e.target.value)} />
                            <input id='actual-btn' onChange={handleChange} className='inputs' type='file' placeholder='upload cv' style={{paddingTop:"5px"}} hidden/>
                            <label htmlFor="actual-btn">Choose File</label>
                            <span id="file-chosen">{filename}</span>
                        <button className="submit" onClick={handleClick}>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Careers
