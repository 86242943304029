import React from 'react'

function ContentBox2({type, txt}) {
    return (
        <div>
            <div style={{padding:"4px 30px", display:"flex", flexDirection:"row",width:"90%",marginLeft:"5%", flex:"1", backgroundColor: "rgb(255, 246, 227)", fontFamily:"calibri", fontSize:"18px", borderLeft:" 5px solid #6d4c41"}}>
                    {type === "over" && <div style={{display:"flex", padding:"20px"}}>
                    <li>{txt}</li>
                    </div>}
                </div>
        </div>
    )
}

export default ContentBox2
