import { Timeline } from 'antd';
import React, { useEffect, useState } from 'react'
import SimpleMap from './SimpleMap'
import 'antd/dist/antd.css';
import loadv from "../Assets/load.mp4"
import loadm from "../Assets/loadphone.mp4"

function Contactus() {
    const [name, setname] = useState("");
    const [message, setmessage] = useState("")
    const [phone, setphone] = useState("")
    const [email, setemail] = useState("")
    const handleClick = (e) => {
        e.preventDefault();
       window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=hrg@nilcoindia.in&su=Applying for job&body=Name: ${name}, Contact: ${phone}, Email: ${email}, Message: ${message}&tf=1`);
     }
    const [load, setLoad] = useState(true)
    useEffect(() => {
            setLoad(true)
            setTimeout(() => {
                setLoad(false)
            }, 2000);
        },[])
    return (
        <div>
            {load && <div className='loading' style={{zIndex:"9",width:"100%", backgroundColor:"#f1f1f1", position:"absolute", height:"90%",top:"70px"}}>
                <center>
                    <video className='loader' width="750" height="500" autoPlay >
                    <source src={window.innerWidth > 1200? loadv :loadm} type="video/mp4"/>
                    </video>
                </center>
            </div>}
            <div style={{height:"850px",backgroundImage: `url(contact.png)`, backgroundRepeat:"no-repeat", marginBottom:"50px"}} hidden={load}>
                <div style={{margin:"0 10vw", marginTop:"5vh"}}>
                    <h1 style={{fontFamily:"calibri",fontWeight:"bold"}}>Let's get in touch</h1>
                    <form style={{width:"250px"}}>
                    <input className = 'inputs' type = "text" placeholder="Name" onChange={e => setname(e.target.value)} />
                        <input className = 'inputs' type = "email" placeholder="Email" onChange={e => setemail(e.target.value)} />
                        <input className = 'inputs' type = "tel" placeholder="Phone number" onChange={e => setphone(e.target.value)} />
                        <textarea placeholder="Message" className='inputarea' rows={4}  onChange={e => setmessage(e.target.value)} />
                        <button className="submit" onClick={handleClick}>Submit</button>
                    </form>
                    <div style={{display:"flex", justifyContent:"center", flexDirection:"column", marginTop:"150px"}}>
                        <h3 style={{color:"#6d4c41", fontFamily:"calibri",fontWeight:"bold"}}>Northern India Leather Cloth Mfg. Co. (P)Ltd.</h3>
                        <Timeline style={{marginTop:"20px"}}>
                        <Timeline.Item color="#6d4c41" style={{ fontFamily:"calibri",fontSize:"18px"}}>Plot no. 16 Sector 6, Faridabad, Haryana</Timeline.Item>
                        <Timeline.Item color="#6d4c41" style={{ fontFamily:"calibri",fontSize:"18px"}}>Email ID: hrg@nilcoindia.in</Timeline.Item>
                        <Timeline.Item color="#6d4c41" style={{ fontFamily:"calibri",fontSize:"18px"}}>Phone no:+91-9873344840, +91-9810698884</Timeline.Item>
                        <Timeline.Item color="#6d4c41" style={{ fontFamily:"calibri",fontSize:"18px"}}>Fax no.:0129-2241584</Timeline.Item>
                        </Timeline>
                    </div>
                </div>
            </div>
            <SimpleMap hidden={load} />
        </div>
    )
}

export default Contactus
