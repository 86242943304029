import React from 'react'
import vv from "../Assets/values.png"

function ContentBox({main, url, txt, type, web}) {
    return (
        <div>
            <div className="prds" style={{display:"flex", flexDirection:"row",width:"90%", margin:"20px 0 0 5%", flex:"1", backgroundColor: "rgb(255, 246, 227)", borderLeft:" 5px solid #6d4c41"}}>
                    {type !== 'val' && <div style={{display:"flex", padding:"20px"}}>
                        {type === "product" && <img className="prdsname" src={url} height="300px" width="400px" style={{overflow:"hidden"}} />}
                    </div>}
                    {type  === "product" && <div style={{display:"flex", padding:"20px", fontSize:"18px", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                        <h1 style={{color:"#6d4c41", fontFamily:"calibri",borderBottom:"6px solid #6d4c41", borderRadius:"12px"}}>{main}</h1>
                        {txt}
                    </div>}
                    {type  === "bod" && <div style={{display:"flex", padding:"20px", fontSize:"18px", justifyContent:"center", flexDirection:"column"}}>
                        <h2 style={{color:"#6d4c41", fontFamily:"calibri",borderBottom:"1.5px solid #6d4c41"}}><li className="dots" style={{marginLeft:"-45px"}}>{main}</li></h2>
                        {txt}
                    </div>}
                    {type  === "vv" && <div style={{display:"flex", padding:"20px", fontSize:"18px", justifyContent:"center", flexDirection:"column"}}>
                        <h2 style={{color:"#6d4c41", fontFamily:"calibri",borderBottom:"1.5px solid #6d4c41"}}><li className="dots" style={{marginLeft:"-45px"}}>{main}</li></h2>
                        {txt}
                    </div>}
                    {type  === "ib" && <div style={{display:"flex", padding:"20px", fontSize:"18px", justifyContent:"center", flexDirection:"column"}}>
                        <h3 style={{color:"#6d4c41", fontFamily:"calibri",borderBottom:"1.5px solid #6d4c41"}}><li className="dots" style={{marginLeft:"-45px"}}>{main}</li></h3>
                        {txt}
                        {web === "forge" && <span>Website: <a href='https://www.steelforge.co.in' style={{color:"#6d4c41"}}>www.steelforge.co.in</a></span>}
                        {web === "pearl" && <span>Website: <a href='https://www.pearlmetal.co.in' style={{color:"#6d4c41"}}>www.pearlmetal.co.in</a></span>}
                    </div>}
                </div>
                {type === 'val' && <div style={{display:"flex", paddingLeft:"40px", flexDirection:"column",width:"90%", margin:"-30px 0 0 5%", flex:"1", backgroundColor: "rgb(255, 246, 227)", borderLeft:" 5px solid #6d4c41"}}>
                <div style={{display:"flex", padding:"20px", fontSize:"18px", justifyContent:"center", flexDirection:"column"}}>
                        <h2 style={{color:"#6d4c41", fontFamily:"calibri",borderBottom:"1.5px solid #6d4c41", borderBottomLeftRadius:0}}><li className="dots" style={{marginLeft:"-45px"}}>{main}</li></h2>
                        <p>Our values have been woven around seven major aspects which are evidently visible in all the activities performed by the company</p>
                        <img id='valuesimg' src={vv} height="200px" width="600px"/>
                    </div>
                    </div>}
        </div>
    )
}

export default ContentBox
