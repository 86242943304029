import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React from 'react'

import insoles from "../Assets/insoles.jpg"
import flipflops from "../Assets/flipflops.jpg"
import sandals from "../Assets/sandals.jpg"
import legguard from "../Assets/legguard.jpg"
import equipments from "../Assets/equipments.jpg"
import baseball from "../Assets/baseball.jpeg"
import rugby from "../Assets/rugby.jpg"
import purses from "../Assets/purses.jpg"
import eyewearcase from "../Assets/eyewearcase.jpg"
import bag from "../Assets/bag.jpg"
import wallets from "../Assets/wallets.jpg"
import raincoat from "../Assets/raincoat.jpg"


function CommonCarousel({type}) {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
    return (
        <div className='cc' style={{ marginTop:"50px"}} >
            <center>
            <Carousel
            arrows={false}
            containerClass="carousel-container"
            autoPlaySpeed={2500}
            autoPlay={true}
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            keyBoardControl={true}
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-50-px">

            {type === 'foot' && <div><img src={insoles} alt={"insoles"} height="150px" width="200px" />
            <p style={{fontFamily:"calibri", fontSize:"25px"}}>Insoles</p>
            </div>}
            {type === 'foot' && <div><img src={flipflops} alt={"flipflops"} height="150px" width="200px" />
            <p style={{fontFamily:"calibri", fontSize:"25px"}}>Flipflops</p></div>}
            {type === 'foot' && <div><img src={sandals} alt={"sandals"} height="150px" width="200px" />
            <p style={{fontFamily:"calibri", fontSize:"25px"}}>Sandals</p></div>}          

            {type === 'sport' && <div><img src={legguard} alt={"legguard"} height="150px" width="200px" />
            <p style={{fontFamily:"calibri", fontSize:"25px"}}>Legguard</p></div>}
            {type === 'sport' && <div><img src={equipments} alt={"equipments"} height="150px" width="200px" />
            <p style={{fontFamily:"calibri", fontSize:"25px"}}>Basketball</p></div>}
            {type === 'sport' && <div><img src={baseball} alt={"baseball"} height="150px" width="200px" />
            <p style={{fontFamily:"calibri", fontSize:"25px"}}>Baseball</p></div>}         
            {type === 'sport' && <div><img src={rugby} alt={"rugby"} height="150px" width="200px" />
            <p style={{fontFamily:"calibri", fontSize:"25px"}}>Rugbyball</p></div>}         

            {type === 'leather' && <div><img src={purses} alt={"purses"} height="150px" width="200px" />
            <p style={{fontFamily:"calibri", fontSize:"25px"}}>Purses</p></div>}
            {type === 'leather' && <div><img src={eyewearcase} alt={"eyewearcase"} height="150px" width="200px" />
            <p style={{fontFamily:"calibri", fontSize:"25px"}}>Eyewear Cases</p></div>}
            {type === 'leather' && <div><img src={bag} alt={"bag"} height="150px" width="200px" />
            <p style={{fontFamily:"calibri", fontSize:"25px"}}>Bags</p></div>}          
            {type === 'leather' && <div><img src={raincoat} alt={"raincoat"} height="150px" width="200px" />
            <p style={{fontFamily:"calibri", fontSize:"25px"}}>Raincoats</p></div>}          
            {type === 'leather' && <div><img src={wallets} alt={"wallets"} height="150px" width="200px" />
            <p style={{fontFamily:"calibri", fontSize:"25px"}}>Wallets</p></div>}          

            
          </Carousel>
          </center>
        </div>
    )
}

export default CommonCarousel
