import React from 'react'

function SimpleMap({hidden}) {
    return (
        <div hidden={hidden} >
            <iframe 
            width="100%"
            height="400" 
            frameBorder="0" 
            scrolling="no" 
            marginHeight="0" 
            marginWidth="0" 
            id="gmap_canvas" 
            title="map"
            src="https://maps.google.com/maps?width=887&amp;height=400&amp;hl=en&amp;q=Northern%20India%20Greatest%20Clothes,%20Plot%20No-%2016,%20sector%206,%20Faridabad%20Faridabad+(Nilco%20India)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
            </iframe> 
            <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=a0e40fae4d81e6618d99a992adaeddc76445b4e7'>
            </script>
          </div>
    )
}

export default SimpleMap
