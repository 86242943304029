import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import React from 'react'
import factory from "../Assets/factory.png"
import research from "../Assets/research.png"
import quality from "../Assets/quality.png"
import soln from "../Assets/soln.png"

function QualityAssurance() {
    return (
        <div style={{padding:"20px", backgroundColor:"#EFEDED"}}>
            {/* <div style={{display:"flex", backgroundColor:"whitesmoke", justifyContent:"space-evenly"}}>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}><h3>In the crowded field of PVC products Manufacturers or converters, Nilco stands out with its widest product range and one of largest manufacturing capacity in India. We are quite literally a Nilco with the largest multiple processing capabilities in the PVC, PU industry. We are one of the topmost leading Indian PVC Exporter and known for its quality products.</h3>
            </div>
            </div>
            <div style={{display:"flex", backgroundColor:"whitesmoke", justifyContent:"space-evenly", height:"500px"}}>
                
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>sddf</div>
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>sdfdsf</div>
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>sdfsdf</div>
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>sdf</div>
                
            </div> */}
            <center>
                <h1 style={{fontFamily:"calibri", color:"#6d4c41"}}>Quality Assurance</h1>
                <h5 style={{padding:" 20px 60px"}}>In the crowded field of PVC products Manufacturers or converters, Nilco stands out with its widest product range and one of largest manufacturing capacity in India. We are quite literally a marvel with the largest multiple processing capabilities in the PVC, PU industry. We are one of the topmost leading Indian PVC Exporter and known for its quality products.</h5>
            <MDBContainer className='p-4'>
                <MDBRow>
                    <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
                        <img style={{marginBottom:"20px"}} className="hvrlogos" src={factory} alt={"logo"} />
                        <h5>EXPERTISE & ADVANCED INFRASTRUCTURE</h5>
                    </MDBCol>
                    <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
                        <img style={{marginBottom:"20px"}} className="hvrlogos" src={quality} alt={"logo"} />
                        <h5>DELIVERING QUALITY PRODUCT</h5>
                    </MDBCol>
                    <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
                        <img style={{marginBottom:"20px"}} className="hvrlogos" src={research} alt={"logo"} />
                        <h5>HIGHLY FOCUSED DEPARTMENT</h5>
                    </MDBCol>
                    <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
                        <img style={{marginBottom:"20px"}} className="hvrlogos" src={soln} alt={"logo"} />
                        <h5>CUSTOMISED SOLUTIONS FOR YOUR NEEDS</h5>
                    </MDBCol>
                    
                </MDBRow>
            </MDBContainer>
            </center>
        </div>
    )
}

export default QualityAssurance

